import { ArrowLeftOutlined, ArrowRightOutlined, ArrowsAltOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Popover, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { aryballeColorPalette, defaultColorPalette, colorToTransparent } from '../../../compute/colormap';
import { defaultPlotlyArguments, getGroupedMap } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectColormap,
  selectColormapRef,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectModel,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
  setBoundariesMap,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryGroupEnvelope, AryRecord, ComparisonMeasuresType, ComparisonSensogramSet, ZoneSelectorType } from '../../../types/analysisTypes';
import RecordsLegend from '../../RecordsLegend';
import FullscreenGraphicModal from '../FullscreenGraphicModal';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import lockAll from '../../../assets/images/lockAll.svg';

export const MultiSensogramQualityControl: React.FC<{ showLegend?: boolean; isStatic?: boolean; qualityControlMode?: boolean }> = (props) => {
  const { showLegend, isStatic, qualityControlMode } = props;

  const { authState } = useOktaOrQueryAuth();

  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const sessionID = useAppSelector(selectSessionID);
  const cmap = useAppSelector(selectColormap);
  const cmapRef = useAppSelector(selectColormapRef);
  const records = useAppSelector(selectRecords);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const model = useAppSelector(selectModel);

  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [groupEnvelopes, setGroupEnvelopes] = useState<ComparisonSensogramSet[]>([]);
  const [error, setError] = useState('');
  const [effectiveCommonAnalyteLeft, setEffectiveCommonAnalyteLeft] = useState(0);
  const [effectiveCommonAnalyteRight, setEffectiveCommonAnalyteRight] = useState(0);
  const [shouldDrawCommonBoundaries, setShouldDrawCommonBoundaries] = useState(true);
  const [zoneSelectorType, setZoneSelectorType] = useState<ZoneSelectorType>(ZoneSelectorType.Analyte);
  const [_cmap, setCmap] = useState<Record<string, string> | undefined>();

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/comparison_temporal_profile?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,
        model,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedGroupEnvelopes: ComparisonSensogramSet[]) => {
        setGroupEnvelopes(receivedGroupEnvelopes);
        setError('');
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState, sessionID, aggregatePeptides, subtractItemName, excludedRecordIDs, excludedSpots, excludedPeptides, model]);

  useEffect(() => {
    if (cmapRef !== undefined) {
      setCmap({ ...cmapRef[ComparisonMeasuresType.Reference], ...cmapRef[ComparisonMeasuresType.Test], ...cmapRef[ComparisonMeasuresType.Other] });
    }
  }, [cmap, cmapRef]);

  useEffect(() => {
    if (groupEnvelopes === undefined || _cmap === undefined) return;

    if (groupEnvelopes.length === 0) return;

    const _plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };
    _plotlyLayout.dragmode = 'select';
    _plotlyLayout.yaxis = { title: { text: 'Normalized intensity' }, automargin: true };
    _plotlyLayout.xaxis = { title: { text: 'Time, sec' }, automargin: true };
    _plotlyLayout.annotations = [];
    if (showLegend) {
      _plotlyLayout.showlegend = true;
      _plotlyLayout.legend = {
        orientation: 'v',
      };
    }

    let _plotlyData: Plotly.Data[] = [];

    groupEnvelopes.forEach((el) => {
      const groupName = el.Name;
      const groupValues = el.Values;

      _plotlyData.push({
        type: 'scatter',
        name: `${groupName}`,
        legendgroup: groupName,
        x: groupValues.map((_, i) => i).map((e) => e.toFixed(1)),
        y: groupValues.map((e) => e.toFixed(3)),
        line: {
          color: _cmap[groupName],
          width: 3,
        },
      });
    });

    setPlotlyData(_plotlyData);
    setPlotlyLayout(_plotlyLayout);
  }, [groupEnvelopes, _cmap]);

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  let plotlyConfig: Partial<Plotly.Config> = { ...defaultPlotlyArguments.config };
  plotlyConfig.displayModeBar = true;
  plotlyConfig.modeBarButtons = [['zoom2d', 'select2d'] as Plotly.ModeBarDefaultButtons[]];
  if (isStatic) {
    plotlyConfig.staticPlot = isStatic;
  }

  if (plotlyData.length === 0) {
    return null;
  }

  return (
    <>
      <div tabIndex={0} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        {isStatic ? null : (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Space>
              <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
            </Space>
          </div>
        )}
        <Plot
          divId="sensogram_scatter_plot"
          debug={true}
          data={plotlyData}
          layout={plotlyLayout}
          config={plotlyConfig}
          style={defaultPlotlyArguments.style}
          useResizeHandler={true}
          onSelected={(e) => {
            if (e.range === undefined) {
              return;
            }
            if (records === undefined) {
              return;
            }

            let range = e.range.x;
            let [startTime, endTime] = range;
          }}
        />
        <FullscreenGraphicModal title="Dataset" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: '10px' }}>
            <Plot
              divId="sensogram_scatter_plot"
              debug={true}
              data={plotlyData}
              layout={plotlyLayout}
              config={plotlyConfig}
              style={defaultPlotlyArguments.style}
              useResizeHandler={true}
              onSelected={(e) => {
                if (e.range === undefined) {
                  return;
                }
                if (records === undefined) {
                  return;
                }

                let range = e.range.x;
                let [startTime, endTime] = range;
              }}
            />
            <RecordsLegend qualityControlMode />
          </div>
        </FullscreenGraphicModal>
      </div>
    </>
  );
};
