/* ---------------------------
      ANALYSIS CONFIG
------------------------------ */
export interface AnalysisConfig {
  SessionID: string;
  BoundariesMap: Record<number, number[]>;
  ExcludedRecordIDs: number[];
  AggregatePeptides: boolean;

  CurrentRecordID: number;
  CurrentItemName: string;
  SensogramRenderType: SensogramRenderType;

  SubtractItemName: string;
  PCAEigenvectors: number[][];
  PCAEigenvalues: number[];
  ExcludedSpots: string[];
  ExcludedPeptides: string[];

  HumidityCompensation: HumidityCompensationConfig;
  ChemicalCalibrationItemNames: string[];
  ExcludedFirstCycleParamState: boolean;

  Colormap?: Record<string, string>;
  ColormapRef?: Record<string, Record<string, string>>;
  Records?: AryRecord[];
  RecordData: AryRecord | null;
  SigmaMultiplier: number;
  ComparisonParameters: ComparisonConfig;
  Model?: ModelMetadata;
}

export type ModelMetadata = {
  Name: string;
  Label: string;
  Location: string;
  Features: string[];
  Classes: string[];
  Reference: string;
};

export enum DisplayResult {
  AllResult = 'All Result',
  SignatureResult = 'Signature Result',
  IntensityResult = 'Intensity Result',
}

export enum CustomResult {
  Custom = 'Custom',
}

export interface ComparisonConfig {
  ThresholdIntensity: number;
  ThresholdSignature: number;
  QualityControlMethod: DisplayResult | CustomResult;
  Interpretation: number;
}

export interface HumidityCompensationConfig {
  CalibrantName: string;
  PositionOffset: number;
  SubstractionGain: number;
}

export type HumidityCalibrantCheck = {
  Success: boolean;
  Code: HumidityWarningCode;
};

export enum HumidityWarningCode {
  WARN_HCAL_TIME_GAP = 'WARN_HCAL_TIME_GAP',
  ERR_HCAL_ALL_UNSELECTED = 'ERR_HCAL_ALL_UNSELECTED',
}

export enum SensogramRenderType {
  Record = 'Record',
  Item = 'Item',
  Dataset = 'Dataset',
}

/* ---------------------------
          SENSOGRAM
------------------------------ */
export interface AryGroupEnvelope {
  GroupName: string;
  MedianDataSeries: number[];
  MinDataSeries: number[];
  MaxDataSeries: number[];
}

export interface AryRecordEnvelope {
  RecordID: number;
  MedianDataSeries: number[];
  MinDataSeries: number[];
  MaxDataSeries: number[];
}

export enum TemperatureSeriesType {
  SensorTemperature = 0,
  ThermodesorptionTemperature,
}

export const TemperaturePrettyNameMap: Record<number, { name: string; unit: string }> = {
  0: {
    name: 'Sensor Temp',
    unit: '°C',
  },
  1: {
    name: 'Amplifier Temp',
    unit: '°C',
  },
};

export enum ZoneSelectorType {
  Baseline = 0,
  Analyte,
}

export enum BoundariesLockMode {
  Measure = 0,
  Group = 1,
  All = 2,
}

/* ---------------------------
          SIGNATURE/S
------------------------------ */
export interface Signature {
  RecordID: number;
  Peptides: string[];
  RawSignature: number[];
  NormalizedSignature: number[];
  EncodedSignature: string;
}

export enum MultiSignaturesFigureType {
  Radar = 0,
  Heatmap,
}

export enum ItemSignaturesFigureType {
  Radar = 0,
  Heatmap,
}

export enum SignatureFigureType {
  Radar = 0,
  Rose,
  Bar,
  Spatial,
  Chemistry,
}

export enum NormType {
  Measured = 0,
  Normalized,
}

/* ---------------------------
              PCA
------------------------------ */
export interface PCASet {
  Projections: number[][];
  Vectors: number[][];
  Variances: number[];
  Labels: string[];
}

export enum PipeType {
  Measured = 'unnormalized_sig',
  Normalized = 'normalized_sig',
  Temporal = 'temporal_profile',
  TemporalSensor = 'temporal_profile_by_sensor',
}

export const pipeLabels: { [key in PipeType]: string } = {
  [PipeType.Normalized]: 'Normalized signatures',
  [PipeType.Measured]: 'Measured signatures',
  [PipeType.Temporal]: 'Temporal profile',
  [PipeType.TemporalSensor]: 'Temporal profile by sensor',
};

/* ---------------------------
          INTENSITY
------------------------------ */
export interface AllIntensitySet {
  Signature: IntensitySet;
  Sensogram: IntensitySet;
  Humidity: IntensitySet;
  SunriseCo2: IntensitySet;
  PidVoc: IntensitySet;
  ZephyrAirflow: IntensitySet;
  Pms1: IntensitySet;
  Pms25: IntensitySet;
  Pms10: IntensitySet;
  BmeVoc: IntensitySet;
  BmeCo2: IntensitySet;
}

export interface IntensitySet {
  Averages: number[];
  Apexes: number[];
  Areas: number[];
  FullAreas: number[];
  Labels: string[];
}

export enum IntensitySource {
  Signature = -1,
  Sensogram = 0,
  Humidity,
  SunriseCo2,
  PidVoc,
  ZephyrAirflow,
  Pms1,
  Pms25,
  Pms10,
  BmeVoc,
  BmeCo2,
}

export enum IntensityType {
  Average = 0,
}

export enum IntensityFigureType {
  Box = 0,
  Bar,
  Temporal,
}

/* ---------------------------
        CHEMICAL FAMILY
------------------------------ */
export interface NamedEntity {
  ID: number;
  Name: string;
}
export interface ChemicalFamily extends NamedEntity {}

export interface PredictedChemicalFamily {
  Probabilities: number[];
  Targets: ChemicalFamily[];
}

/* ---------------------------
      AUXILARY SENSORS
------------------------------ */
export enum AuxiliarySensorType {
  SunriseCo2 = 2,
  PidVoc,
  ZephyrAirflow,
  Pms1,
  Pms25,
  Pms10,
  BmeVoc,
  BmeCo2,
  PidRawVoc = 12,
  BmeHumidity,
  BmeTemperature,
  BmeRawPressure,
  BmeBreathVoc,
}

export const AuxiliarySensorPrettyNameMap: Record<number, { name: string; unit: string }> = {
  '-1': {
    name: 'Signature',
    unit: 'rad',
  },
  0: {
    name: 'Sensogram',
    unit: 'rad',
  },
  1: {
    name: 'Humidity (HIH)',
    unit: '%RH',
  },
  2: {
    name: 'CO2 (Sunrise)',
    unit: 'ppm',
  },
  3: {
    name: 'tVOC (PID)',
    unit: 'ppm',
  },
  4: {
    name: 'Flowrate (Zephyr)',
    unit: 'ml/min',
  },
  5: {
    name: 'PM-1',
    unit: 'μg/m3',
  },
  6: {
    name: 'PM-2.5',
    unit: 'μg/m3',
  },
  7: {
    name: 'PM-10',
    unit: 'μg/m3',
  },
  8: {
    name: 'tVOC (BME)',
    unit: 'ppm',
  },
  9: {
    name: 'CO2 (BME)',
    unit: 'ppm',
  },
  12: {
    name: 'RawVOC (PID)',
    unit: 'volt',
  },
  13: {
    name: 'Humidity (BME)',
    unit: '%RH',
  },
  14: {
    name: 'Temperature (BME)',
    unit: 't',
  },
  15: {
    name: 'RawPressure (BME)',
    unit: 'Pa',
  },
  16: {
    name: 'BreathVoc (BME)',
    unit: 'ppm',
  },
};

export type EligibleAditionalSensorType = {
  Type: string;
  Name: string;
  Unit: string | null;
};

/* ---------------------------
          MATRIX
------------------------------ */
export type DistanceMatrix = {
  InterMeasure: number[][];
  GroupMean: number[][];
  GroupStd: number[][];
  GroupLabels: string[];
};

export type ConfusionMatrix = {
  Values: number[][];
  PredictedLabels: string[];
  TrueLabels: string[];
};

/* ---------------------------
          RECORD
------------------------------ */
export interface AryRecord {
  ID: number;
  RunID: string;
  DeviceID: string;
  ItemName: string;
  CycleNumber: number;

  AbsoluteTimestamp: number;
  RelativeTimeSeries: number[];
  AbsoluteTimeSeries: number[];
  DataSeries: number[][];
  HumiditySeries: number[];
  TemperatureSeries: number[];
  ThermodesorptionTemperatureSeries: number[];
  AdditionalSensorsSeries: Record<string, number[]>;

  // SDOK Sensors
  SunriseCo2Series: number[];
  PidVocSeries: number[];
  ZephyrAirflowSeries: number[];
  Pms1Series: number[];
  Pms25Series: number[];
  Pms10Series: number[];
  BmeVocSeries: number[];
  BmeCo2Series: number[];
  BmeHumiditySeries: number[];
  BmeTemperatureSeries: number[];
  BmeRawPressureSeries: number[];
  BmeBreathVocSeries: number[];
  PidRawVocSeries: number[];

  Sensors: string[];

  Boundaries: [number, number, number, number];

  Tags: string[];
}

export enum PeptideSet {
  Unknown = -1,
  NonStandard = 0,
  POR1 = 1,
  POR2 = 2,
  POR3 = 3,
  POR4 = 4,
}

/* ---------------------------
          COMPARISON
------------------------------ */
export type ResultComparison = {
  DistanceAvg: number;
  DistanceStd: number;
  InterpretedDecision: InterpretationDecisionType;
  ObjectiveDecision: ObjectiveDecisionType;
};

export type CustomComparisonResult = Record<SensogramRenderType, UnitComparisonResult[]>;

export type UnitComparisonResult = {
  ID: string | number;
  ObjectiveDecision: string;
  InterpretedDecision: string;
  Probability: number;
};

export enum InterpretationDecisionType {
  NA = 'NA',
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export enum ObjectiveDecisionType {
  NA = 'NA',
  SIMILAR = 'SIMILAR',
  DIFFERENT = 'DIFFERENT',
}

export interface AvgErrValue {
  avg: number;
  err: number; // absolute error
}

export enum ComparisonMeasuresType {
  Reference = 'Reference',
  Test = 'Test',
  Other = 'Other',
}

export interface ComparisonInsensitySet {
  GroupedIntensities: Record<string, number[]>;
  GroupedNames: Record<string, string[]>;
}

export interface ComparisonSensogramSet {
  Name: string;
  Values: number[];
}
