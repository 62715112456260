import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AnalysisConfig, AryRecord, CustomResult, DisplayResult, ModelMetadata, SensogramRenderType } from '../../types/analysisTypes';

const initialState: AnalysisConfig = {
  SessionID: '',
  BoundariesMap: {},
  ExcludedRecordIDs: [],

  CurrentRecordID: 0,
  CurrentItemName: '',
  SensogramRenderType: SensogramRenderType.Record,

  AggregatePeptides: true,
  SubtractItemName: '',
  PCAEigenvectors: [],
  PCAEigenvalues: [],
  RecordData: null,
  ExcludedSpots: [],
  ExcludedPeptides: [],

  HumidityCompensation: {
    CalibrantName: '',
    PositionOffset: 0,
    SubstractionGain: 1,
  },
  ChemicalCalibrationItemNames: [],
  SigmaMultiplier: 3,
  ComparisonParameters: {
    ThresholdIntensity: 10,
    ThresholdSignature: 1.5,
    QualityControlMethod: DisplayResult.AllResult,
    Interpretation: 1,
  },
  ExcludedFirstCycleParamState: true,
  Model: undefined,
};

export const analysisConfigSlice = createSlice({
  name: 'analysisConfig',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSessionID: (state, action: PayloadAction<string>) => {
      state.SessionID = action.payload;
    },
    setRecordData: (state, action: PayloadAction<AryRecord | null>) => {
      state.RecordData = action.payload;
    },
    setBoundaries: (state, action: PayloadAction<{ recordID: number; boundaries: number[] }>) => {
      let { recordID, boundaries } = action.payload;
      state.BoundariesMap[recordID] = boundaries;
    },
    setBoundariesMap: (state, action: PayloadAction<Record<number, number[]>>) => {
      state.BoundariesMap = { ...action.payload };
    },
    setExcludedRecordIDs: (state, action: PayloadAction<number[]>) => {
      state.ExcludedRecordIDs = action.payload;
    },
    setCurrentRecordID: (state, action: PayloadAction<number>) => {
      state.CurrentRecordID = action.payload;
    },
    setCurrentItemName: (state, action: PayloadAction<string>) => {
      state.CurrentItemName = action.payload;
    },
    setSensogramRenderType: (state, action: PayloadAction<SensogramRenderType>) => {
      state.SensogramRenderType = action.payload;
    },
    setColormap: (state, action: PayloadAction<Record<string, string>>) => {
      state.Colormap = action.payload;
    },
    setColormapRef: (state, action: PayloadAction<Record<string, Record<string, string>>>) => {
      state.ColormapRef = action.payload;
    },
    setRecords: (state, action: PayloadAction<AryRecord[]>) => {
      state.Records = action.payload;
    },
    setAggregatePeptides: (state, action: PayloadAction<boolean>) => {
      state.AggregatePeptides = action.payload;
    },
    setSubtractItemName: (state, action: PayloadAction<string>) => {
      state.SubtractItemName = action.payload;
    },
    setPCAEigenvectors: (state, action: PayloadAction<number[][]>) => {
      state.PCAEigenvectors = action.payload;
    },
    setPCAEigenvalues: (state, action: PayloadAction<number[]>) => {
      state.PCAEigenvalues = action.payload;
    },
    setExcludedSpots: (state, action: PayloadAction<string[]>) => {
      state.ExcludedSpots = action.payload;
    },
    setExcludedPeptides: (state, action: PayloadAction<string[]>) => {
      state.ExcludedPeptides = action.payload;
    },

    setHumidityCompensationCalibrantName: (state, action: PayloadAction<string>) => {
      state.HumidityCompensation.CalibrantName = action.payload;
    },
    setHumidityCompensationPositionOffset: (state, action: PayloadAction<number>) => {
      state.HumidityCompensation.PositionOffset = action.payload;
    },
    setHumidityCompensationSubstractionGain: (state, action: PayloadAction<number>) => {
      state.HumidityCompensation.SubstractionGain = action.payload;
    },

    setChemicalCalibrationItemNames: (state, action: PayloadAction<string[]>) => {
      state.ChemicalCalibrationItemNames = action.payload;
    },

    setSigmaMultiplier: (state, action: PayloadAction<number>) => {
      state.SigmaMultiplier = action.payload;
    },

    setThresholdIntensity: (state, action: PayloadAction<number>) => {
      state.ComparisonParameters.ThresholdIntensity = action.payload;
    },

    setThresholdSignature: (state, action: PayloadAction<number>) => {
      state.ComparisonParameters.ThresholdSignature = action.payload;
    },

    setQualityControlMethod: (state, action: PayloadAction<DisplayResult | CustomResult>) => {
      state.ComparisonParameters.QualityControlMethod = action.payload;
    },

    setInterpretation: (state, action: PayloadAction<number>) => {
      state.ComparisonParameters.Interpretation = action.payload;
    },

    setExcludedFirstCycleParamState: (state, action: PayloadAction<boolean>) => {
      state.ExcludedFirstCycleParamState = action.payload;
    },

    setModel: (state, action: PayloadAction<ModelMetadata>) => {
      state.Model = action.payload;
    },

    resetAnalysisConfig: (state) => {
      return initialState;
    },
  },
});

export const {
  setSessionID,
  setRecordData,
  setBoundaries,
  setBoundariesMap,
  setExcludedRecordIDs,

  setCurrentRecordID,
  setCurrentItemName,
  setSensogramRenderType,

  setColormap,
  setColormapRef,
  setRecords,
  setAggregatePeptides,
  setSubtractItemName,
  setPCAEigenvectors,
  setPCAEigenvalues,
  setExcludedSpots,
  setExcludedPeptides,

  setHumidityCompensationCalibrantName,
  setHumidityCompensationPositionOffset,
  setHumidityCompensationSubstractionGain,

  setChemicalCalibrationItemNames,

  setSigmaMultiplier,
  setThresholdIntensity,
  setThresholdSignature,
  setQualityControlMethod,
  setInterpretation,
  setExcludedFirstCycleParamState,
  setModel,

  resetAnalysisConfig,
} = analysisConfigSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSessionID = (state: RootState) => state.analysisConfig.SessionID;
export const selectRecordData = (state: RootState) => state.analysisConfig.RecordData;
export const selectExcludedRecordIDs = (state: RootState) => state.analysisConfig.ExcludedRecordIDs;
export const selectBoundariesMap = (state: RootState) => state.analysisConfig.BoundariesMap;

export const selectCurrentRecordID = (state: RootState) => state.analysisConfig.CurrentRecordID;
export const selectCurrentItemName = (state: RootState) => state.analysisConfig.CurrentItemName;
export const selectSensogramRenderType = (state: RootState) => state.analysisConfig.SensogramRenderType;

export const selectColormap = (state: RootState) => state.analysisConfig.Colormap;
export const selectColormapRef = (state: RootState) => state.analysisConfig.ColormapRef;
export const selectRecords = (state: RootState) => state.analysisConfig.Records;
export const selectAggregatePeptides = (state: RootState) => state.analysisConfig.AggregatePeptides;
export const selectSubtractItemName = (state: RootState) => state.analysisConfig.SubtractItemName;
export const selectPCAEigenvectors = (state: RootState) => state.analysisConfig.PCAEigenvectors;
export const selectPCAEigenvalues = (state: RootState) => state.analysisConfig.PCAEigenvalues;
export const selectExcludedSpots = (state: RootState) => state.analysisConfig.ExcludedSpots;
export const selectExcludedPeptides = (state: RootState) => state.analysisConfig.ExcludedPeptides;

export const selectHumidityCompensationCalibrantName = (state: RootState) => state.analysisConfig.HumidityCompensation.CalibrantName;
export const selectHumidityCompensationPositionOffset = (state: RootState) => state.analysisConfig.HumidityCompensation.PositionOffset;
export const selectHumidityCompensationSubstractionGain = (state: RootState) => state.analysisConfig.HumidityCompensation.SubstractionGain;

export const selectChemicalCalibrationItemNames = (state: RootState) => state.analysisConfig.ChemicalCalibrationItemNames;

export const selectSigmaMultiplier = (state: RootState) => state.analysisConfig.SigmaMultiplier;
export const selectThresholdIntensity = (state: RootState) => state.analysisConfig.ComparisonParameters.ThresholdIntensity;
export const selectThresholdSignature = (state: RootState) => state.analysisConfig.ComparisonParameters.ThresholdSignature;
export const selectQualityControlMethod = (state: RootState) => state.analysisConfig.ComparisonParameters.QualityControlMethod;
export const selectInterpretation = (state: RootState) => state.analysisConfig.ComparisonParameters.Interpretation;
export const selectExcludedFirstCycleParamState = (state: RootState) => state.analysisConfig.ExcludedFirstCycleParamState;
export const selectModel = (state: RootState) => state.analysisConfig.Model;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default analysisConfigSlice.reducer;
