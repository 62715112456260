import { useOktaAuth } from '@okta/okta-react';
import { CSSProperties, Key, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, nonStandardPeptidesColor, peptideColorPaletteVdW } from '../../../../compute/colormap';
import { selectExcludedPeptides, selectExcludedSpots, setExcludedPeptides, setExcludedSpots } from '../../../../features/analysisConfig/analysisConfigSlice';
import { PeptideSet } from '../../../../types/analysisTypes';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../../types/userType';

type PeptidesSelectorGridProps = {
  style: { [K in Key]: CSSProperties };
  spots: String[] | undefined;
  peptidesSetType: PeptideSet;
};

const PeptidesSelectorGrid: React.FC<PeptidesSelectorGridProps> = ({ style, spots, peptidesSetType }) => {
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const [peptides, setPeptides] = useState<string[] | undefined>();
  const dispatch = useAppDispatch();

  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (spots) {
      if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) setPeptides(Array.from(new Set(spots.map((s) => parseInt(s.toString()).toString()))));
      else setPeptides(spots.map((s) => s.toString()));
    }
  }, [spots, userInfo]);

  const createStyle = (peptide: string) => {
    const featureFlag = userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled);
    let containsSomeSpots: boolean = false;

    if (featureFlag === true) {
      const matchingSpots = spots?.filter((s) => parseInt(s.toString()).toString() === peptide);
      let containsAllSpots: boolean = false;
      let peptideCoordinateInts: string[] = [];

      if (matchingSpots) {
        peptideCoordinateInts = matchingSpots?.map((s) => s.toString().split('[')[1].split(']')[0]);
        const containsSpots: boolean[] = peptideCoordinateInts.map((spot) => excludedSpots.includes(spot.toString()));
        containsAllSpots = !containsSpots.includes(false);
        containsSomeSpots = containsSpots.includes(true);
      }

      if (containsAllSpots) return style.diselectedPeptides;
    } else {
      if (excludedPeptides.findIndex((el) => el === peptide) !== -1) return style.diselectedPeptides;
    }

    if (peptidesSetType !== PeptideSet.NonStandard) {
      let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;

      if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2 || peptidesSetType === PeptideSet.POR3 || peptidesSetType === PeptideSet.POR4) {
        color = peptideColorPaletteVdW[parseInt(peptide)];
        if (!color) color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
      }

      if (featureFlag === true && containsSomeSpots) return { color: color, border: `2px solid ${color}`, background: 'white', fontWeight: 700 };
      else return { backgroundColor: color };
    }

    if (peptidesSetType === PeptideSet.NonStandard) {
      let color = nonStandardPeptidesColor[Number(peptide) % nonStandardPeptidesColor.length];
      if (featureFlag === true && containsSomeSpots) return { color: color, border: `2px solid ${color}`, background: 'white', fontWeight: 700 };
      else return { backgroundColor: color };
    }
    if (featureFlag === true && containsSomeSpots) return { color: DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, border: `2px solid ${DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE}`, background: 'white', fontWeight: 700 };
    else return { backgroundColor: DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE };
  };

  const excludePeptides = (peptide: string) => {
    if (userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)) return;

    if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
      const matchingSpots = spots?.filter((s) => parseInt(s.toString()).toString() === peptide);

      let containsAllSpots: boolean = false;
      let peptideCoordinateInts: string[] = [];

      if (matchingSpots) {
        peptideCoordinateInts = matchingSpots?.map((s) => s.toString().split('[')[1].split(']')[0]);
        const containsSpots: boolean[] = peptideCoordinateInts.map((spot) => excludedSpots.includes(spot.toString()));
        containsAllSpots = !containsSpots.includes(false);
      }

      if (containsAllSpots === false) {
        dispatch(setExcludedSpots(Array.from(new Set([...excludedSpots, ...peptideCoordinateInts]))));
      } else {
        dispatch(setExcludedSpots(excludedSpots.filter((spot) => !peptideCoordinateInts.includes(spot))));
      }
    } else {
      if (excludedPeptides.findIndex((el) => el === peptide) === -1) dispatch(setExcludedPeptides([...excludedPeptides, peptide]));
      else {
        const newEcludedSpots = excludedPeptides.filter((el) => el !== peptide);
        dispatch(setExcludedPeptides(newEcludedSpots));
      }
    }
  };

  return (
    <table style={{ borderCollapse: 'separate', marginTop: 25 }}>
      <tbody>
        <tr style={{ display: 'flex', flexWrap: 'wrap' }}>
          {peptides?.map((el, index) => (
            <td style={{ ...style.base, ...style.td, ...{ margin: 2 }, ...createStyle(el.toString()) }} key={index} onClick={() => excludePeptides(el.toString())}>
              {el}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default PeptidesSelectorGrid;
