import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectModel, selectQualityControlMethod, selectRecords, setQualityControlMethod } from '../../../features/analysisConfig/analysisConfigSlice';
import { CustomResult, DisplayResult } from '../../../types/analysisTypes';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { useEffect } from 'react';

type DisplayResultSettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};

const DisplayResultSettings: React.FC<DisplayResultSettingsProps> = ({ userInfo }) => {
  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);
  const qualityControlMethod = useAppSelector(selectQualityControlMethod);
  const model = useAppSelector(selectModel);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  useEffect(() => {
    if (model) dispatch(setQualityControlMethod(CustomResult.Custom));
  }, [model, dispatch]);

  return (
    <div style={{ marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Quality control method </span>
      </div>
      <Select
        value={qualityControlMethod}
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        style={{ width: '100%', marginTop: 10 }}
        showSearch={true}
        placeholder="Quality control method"
        allowClear={false}
        options={(model ? Object.entries(CustomResult) : Object.entries(DisplayResult)).map(([label, value]) => {
          return {
            key: label,
            value: value,
          };
        })}
        onChange={(v) => {
          console.log(v);
          dispatch(setQualityControlMethod(v));
        }}
      />
    </div>
  );
};

export default DisplayResultSettings;
