import { Row, Select, Skeleton } from 'antd';
import CardSection from '../../../components/section/CardSection';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectModel, setModel } from '../../../features/analysisConfig/analysisConfigSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { ModelMetadata } from '../../../types/analysisTypes';

type DashboardModelProps = {};

const DashboardModel: React.FC<DashboardModelProps> = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [models, setModels] = useState<ModelMetadata[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();
  const model = useAppSelector(selectModel);
  const { sessionID } = useParams<{ sessionID: string }>();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) return;

    fetchAuthorizedAPIEndpoint(`/compare/list_models?session_id=${sessionID}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((models: { ModelsMetadata: ModelMetadata[] }) => {
        if (!models) return;

        setModels(models.ModelsMetadata);
        if (!model && models.ModelsMetadata[0]) {
          dispatch(setModel(models.ModelsMetadata[0]));
          setSelectedModel(models.ModelsMetadata[0].Name);
        }
      });
  }, [authState, sessionID, userInfo]);

  useEffect(() => {
    if (!selectedModel) return;
    const model = models.find((m) => m.Name === selectedModel);

    if (model) dispatch(setModel(model));
  }, [selectedModel]);

  return (
    <>
      {model && (
        <CardSection scroll="auto" marginBottom="2%" minHeight="100px">
          <Row justify="space-between" style={{ marginTop: -10 }}>
            <p style={{ fontWeight: 500, marginBottom: 10 }}>Model</p>
          </Row>
          <div>
            <Select
              value={model.Label}
              style={{ width: '100%' }}
              disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
              showSearch={true}
              placeholder="Select a model"
              allowClear={false}
              options={models.map((m) => {
                return {
                  key: m.Name,
                  label: m.Label,
                  value: m.Name,
                };
              })}
              onChange={(m) => {
                setSelectedModel(m);
              }}
            />
          </div>
        </CardSection>
      )}
    </>
  );
};

export default DashboardModel;
