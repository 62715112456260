import { Alert, Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../app/hooks';
import { defaultColorPalette } from '../../../compute/colormap';
import { defaultPlotlyArguments, getGroupedMap, getRecordNameWithBr, sortZippedArrays } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectColormap,
  selectColormapRef,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectModel,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AllIntensitySet, AryRecord, AuxiliarySensorPrettyNameMap, ComparisonInsensitySet, ComparisonMeasuresType, IntensityFigureType, IntensitySet, IntensitySource, IntensityType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import FullscreenGraphicModal from '../FullscreenGraphicModal';

type IntensityFigureProps = {
  isVisibleModal: boolean;
  setIsVisibleModal: (value: React.SetStateAction<boolean>) => void;
  figureType: IntensityFigureType;
  qualityControlMode?: boolean;
};

const IntensityFigureQualityControl: React.FC<IntensityFigureProps> = ({ isVisibleModal, setIsVisibleModal, figureType, qualityControlMode }) => {
  const [allIntensitySet, setAllIntensitySet] = useState<ComparisonInsensitySet | null>(null);

  const boundariesMap = useAppSelector(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const sessionID = useAppSelector(selectSessionID);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const cmap = useAppSelector(selectColormap);
  const cmapRef = useAppSelector(selectColormapRef);
  const records = useAppSelector(selectRecords);
  const model = useAppSelector(selectModel);
  const [_cmap, setCmap] = useState<Record<string, string> | undefined>();

  const [effectiveRecords, setEffectiveRecords] = useState<AryRecord[]>([]);
  const [error, setError] = useState<string | undefined>();

  const { authState } = useOktaOrQueryAuth();

  const [groupedIntensities, setGroupedIntensities] = useState<Record<string, number[]>>();
  const [groupedEffectiveRecords, setGroupedEffectiveRecords] = useState<Record<string, AryRecord[]>>();

  useEffect(() => {
    if (authState === null || !authState.accessToken || !model) return;

    fetchAuthorizedAPIEndpoint(`/compute/comparison_intensities?session_id=${sessionID}&pipe=temporal_profile`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        excludedSpots,
        excludedPeptides,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        model,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedIntensitySet: ComparisonInsensitySet) => {
        setAllIntensitySet(receivedIntensitySet);
        setError(undefined);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState, sessionID, boundariesMap, excludedRecordIDs, aggregatePeptides, subtractItemName, excludedSpots, excludedPeptides, humidityCalibrationCalibrantName, humidityCalibrationPositionOffset, humidityCalibrationSubstractionGain, model]);

  useEffect(() => {
    if (cmapRef !== undefined) setCmap({ ...cmapRef[ComparisonMeasuresType.Reference], ...cmapRef[ComparisonMeasuresType.Test], ...cmapRef[ComparisonMeasuresType.Other] });
  }, [cmap, cmapRef, qualityControlMode]);

  var plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };
  plotlyLayout.bargap = 0.15;
  plotlyLayout.bargroupgap = 0.1;
  plotlyLayout.barmode = 'group';
  plotlyLayout.xaxis = {
    automargin: true,
    type: 'category',
  };
  plotlyLayout.yaxis = {
    automargin: true,
    // title: { text: `${AuxiliarySensorPrettyNameMap[+intensitySource].name}, ${AuxiliarySensorPrettyNameMap[+intensitySource].unit}` },
  };

  var plotlyData: Plotly.Data[] = [];

  var ticktextX: string[] = [];
  var tickvalsX: number[] = [];

  if (_cmap !== undefined && allIntensitySet) {
    if (figureType === +IntensityFigureType.Box) {
      Object.entries(allIntensitySet?.GroupedIntensities).forEach(([groupName, intensities]) => {
        plotlyData.push({
          name: groupName,
          type: 'box',
          hoverinfo: 'name',
          hoverlabel: { namelength: -1 },
          x: intensities.map((_) => groupName),
          y: intensities,
          marker: {
            color: _cmap[groupName],
          },
        });
      });
    } else if (figureType === +IntensityFigureType.Bar) {
      var i = 0;
      Object.entries(allIntensitySet.GroupedIntensities).forEach(([groupName, intensities]) => {
        ticktextX.push(groupName);
        tickvalsX.push(i + intensities.length / 2);

        plotlyData.push({
          name: groupName,
          type: 'bar',
          x: allIntensitySet.GroupedNames[groupName] || [],
          y: intensities,
          hoveron: 'points',
          hoverinfo: 'x+y',
          marker: {
            color: _cmap[groupName],
          },
        });
        i += intensities.length;
      });
      plotlyLayout.xaxis.ticktext = ticktextX;
      plotlyLayout.xaxis.tickvals = tickvalsX;
      const min = Math.max(...Object.values(allIntensitySet.GroupedIntensities).flat());
      const max = Math.min(...Object.values(allIntensitySet.GroupedIntensities).flat());
      plotlyLayout.yaxis.range = [max, min];
    }
  }

  return (
    <>
      {error && error !== '' ? (
        <Alert type="error" message={error} style={{ marginTop: 10, borderRadius: 5, width: '100%' }} />
      ) : (
        <>
          <Plot divId="intensity_box_plot" debug={true} data={plotlyData} useResizeHandler={true} layout={plotlyLayout} config={defaultPlotlyArguments.config} style={defaultPlotlyArguments.style} />
          <FullscreenGraphicModal title="Intensity" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
            <Plot divId="intensity_box_plot" debug={true} data={plotlyData} useResizeHandler={true} layout={plotlyLayout} config={defaultPlotlyArguments.config} style={{ ...defaultPlotlyArguments.style, width: undefined, aspectRatio: '4/3' }} />
          </FullscreenGraphicModal>
        </>
      )}
    </>
  );
};

export default IntensityFigureQualityControl;
