import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, InputRef, Popover, Row, Space, Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useEffect, useRef, useState } from 'react';
import { PeptideSet } from '../types/analysisTypes';
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import { aryballeColorPalette, defaultColorPalette, getColormap } from '../compute/colormap';
import { getPeptideSetType, getSortedUniqueSlice, renderColoredTag, renderDeviceID, renderExposureIcon, renderFwVersion, renderPeptideSetType, renderSwVersion, renderTimeDuration, renderTimestamp, spotsGrid2PeptideSet } from '../compute/utils';
import { useQueryParam } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setVisibleDeleteRuns } from '../features/analysisConfig/sessionInfoSlice';
import { useAppDispatch } from '../app/hooks';
import { useOktaAuth } from '@okta/okta-react/';
import { WithFeatureFlagsCheck } from '../with_feature_flags_check';
import { FeatureFlag, UserClaimsWithTSDB } from '../types/userType';
import { RunMetadata, SelectedRun, TSDBRef } from '../types/runsType';

interface DataType {
  key: string;
  id: string;
  uid: string;
  name: string;
  recordsCount: number;
  timestampStart: number;
  timestampEnd: number;
  items: string[];
  userTags: string[];
  userEmail: string;

  swVersion: string;
  fwVersion: string;
  deviceID: string;
  deviceShellSerial: string;
  deviceCoreSensorSerial: string;
  peptideSetType: PeptideSet;
  spotsGrid: number[];

  calibrationExposure: number;
}

type DataIndex = keyof DataType;

export const RunsTable: FC<{
  runs: RunMetadata[];
  sourceRef: TSDBRef;
  selectedRunsMap: Record<TSDBRef['Name'], SelectedRun[]>;
  setSelectedRunsMap: React.Dispatch<React.SetStateAction<Record<TSDBRef['Name'], SelectedRun[]>>>;
  selectedDeleteRuns?: string[];
  setSelectedDeleteRuns?: React.Dispatch<React.SetStateAction<string[]>>;
}> = (props) => {
  const { runs, selectedRunsMap, sourceRef: tsdbRef, setSelectedRunsMap, selectedDeleteRuns, setSelectedDeleteRuns } = props;
  runs.forEach((run) => {
    run.UserTags = run.Tags.filter((t) => t[0] !== '$');
  });

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [filteredItem, setFilteredItem] = useState<string[]>(getSortedUniqueSlice(runs.map((r) => r.ItemNames)));
  const [uniqueItems, setUniqueItems] = useState<string[]>(getSortedUniqueSlice(runs.map((r) => r.ItemNames)));

  const searchInput = useRef<InputRef>(null);

  const [runIDSearchText, setRunIDSearchText] = useQueryParam('run_id', '');
  const [coreSensorSNSearchText, setCoreSensorSNSearchText] = useQueryParam('cs_sn', '');
  const [userNameSearchText, setUserNameSearchText] = useQueryParam('user_email', '');
  const [selectedItems, setSelectedItems] = useQueryParam('items', '');
  const [shellSNSearchText, setShellSNSearchText] = useQueryParam('shell_sn', '');
  const [runNameSearchText, setRunNameSearchText] = useQueryParam('run_name', '');
  const [selectedRunsQueryParam, setSelectedRunsQueryParam] = useQueryParam('selected_runs', '');

  const dispatch = useAppDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    let entriesStrings: string[] = [];
    Object.entries(selectedRunsMap).forEach(([tsdbRefName, selectedRuns]) => {
      if (selectedRuns.length > 0) {
        entriesStrings.push(`${tsdbRefName}@${selectedRuns.map((r) => r.UID).join(',')}`);
      }
    });
    setSelectedRunsQueryParam(entriesStrings.join(';'));
  }, [selectedRunsMap]);

  // Executes ones on the reception of runs (after /list_runs)
  useEffect(() => {
    setUniqueItems(getSortedUniqueSlice(runs.map((r) => r.ItemNames)));
    setFilteredItem(getSortedUniqueSlice(runs.map((r) => r.ItemNames)));
    let _dataSource = runs.map((run) => {
      console.log(run.ID, run.Device.Info.SpotsGrid);
      const cmap = getColormap(Array.from(new Set(run.ItemNames)));
      return {
        id: run.ID,
        key: run.UID,
        uid: run.UID,
        name: run.Name,
        nameRendered: (
          <Typography.Text style={{ maxWidth: '200px' }} ellipsis={{ tooltip: run.Name }}>
            {run.Name}
          </Typography.Text>
        ),
        recordsCount: run.RecordsCount,
        items: run.ItemNames,
        timestampStart: run.TimestampStart,
        timestampEnd: run.TimestampEnd,
        itemsRendered: (
          <div style={{ overflow: 'scroll', maxHeight: '150px' }}>
            {run.ItemNames.map((itemName) => {
              return renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>);
            })}
          </div>
        ),
        dateTimeRendered: renderTimestamp(run.TimestampStart),
        userTags: run.UserTags,
        userEmail: run.UserEmail,

        // New fields (2022-09-23)
        swVersion: run.SWVersion,
        fwVersion: run.Device && run.Device.Info && run.Device.Info.Versions ? run.Device.Info.Versions.fsp_refdesign_fw : '',
        deviceID: run.Device ? run.Device.ID : '',
        deviceShellSerial: run.Device && run.Device.Info ? run.Device.Info.ShellSerial.replace(/NOA22.\-/i, '') : '', // NOA22{1/2} is clunky and does not provide much information
        deviceCoreSensorSerial: run.Device && run.Device.Info ? run.Device.Info.CoreSensorSerial.replace(/CS22.\-/i, '') : '', // CS22{1/2} is clunky and does not provide much information
        peptideSetType: run.Device && run.Device.Info && run.Device.Info.SpotsGrid ? getPeptideSetType(run.Device.Info.SpotsGrid) : PeptideSet.Unknown,
        spotsGrid: run.Device && run.Device.Info && run.Device.Info.SpotsGrid ? run.Device.Info.SpotsGrid : [],

        calibrationExposure: run.Device && run.Device.Info ? run.Device.Info.CameraExposure : 0,
      } as DataType;
    });
    setDataSource(_dataSource);
  }, [runs]);

  useEffect(() => {
    const _effectiveSelectedRunsMap: Record<string, SelectedRun[]> = {};
    Object.entries(selectedRunsMap).forEach(([k, v]) => {
      _effectiveSelectedRunsMap[k] = [];
    });
    // Parse selectedRunsQueryParam
    if (selectedRunsQueryParam !== '') {
      let entriesStrings = selectedRunsQueryParam.split(';');
      entriesStrings.forEach((entryString) => {
        const [tsdbRefName, runUIDsString] = entryString.split('@');
        const runUIDs = runUIDsString.split(',');
        _effectiveSelectedRunsMap[tsdbRefName] = runUIDs.map((runUID) => ({
          UID: runUID,
          TSDBRef: {
            Name: tsdbRefName,
            Org: '',
            Token: '',
            URL: '',
          },
        }));
      });
      setSelectedRunsMap(_effectiveSelectedRunsMap);
    }
  }, []);

  let uniqueUserTags = getSortedUniqueSlice(runs.map((r) => r.UserTags));
  let uniquePeptideSets = Array.from(new Set(runs.map((r) => (r.Device && r.Device.Info && r.Device.Info.SpotsGrid ? getPeptideSetType(r.Device.Info.SpotsGrid) : PeptideSet.Unknown))));

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex: DataIndex, isTagged: boolean, queryParam: string, setQueryParam: (newValue: string) => void): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              setQueryParam('');
              clearFilters && handleReset(clearFilters, confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      setQueryParam('' + value);
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      let children = queryParam !== '' ? <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[queryParam]} autoEscape textToHighlight={text ? text.toString() : ''} /> : text;
      return (
        <Typography.Text style={{ maxWidth: '200px' }} ellipsis={{ tooltip: text }}>
          {isTagged ? <Tag>{children}</Tag> : children}
        </Typography.Text>
      );
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Run ID',
      dataIndex: 'id',
      key: 'id',
      defaultFilteredValue: runIDSearchText ? [runIDSearchText] : null,
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      ...getColumnSearchProps('id', false, runIDSearchText, setRunIDSearchText),
    },
    {
      title: 'CS',
      dataIndex: 'deviceCoreSensorSerial',
      key: 'deviceCoreSensorSerial',
      defaultFilteredValue: coreSensorSNSearchText ? [coreSensorSNSearchText] : null,
      sorter: (a, b) => (a.deviceCoreSensorSerial > b.deviceCoreSensorSerial ? 1 : -1),
      ...getColumnSearchProps('deviceCoreSensorSerial', true, coreSensorSNSearchText, setCoreSensorSNSearchText),
    },
    {
      title: 'NeOse',
      dataIndex: 'deviceShellSerial',
      key: 'deviceShellSerial',
      defaultFilteredValue: shellSNSearchText ? [shellSNSearchText] : null,
      sorter: (a, b) => (a.deviceShellSerial > b.deviceShellSerial ? 1 : -1),
      ...getColumnSearchProps('deviceShellSerial', true, shellSNSearchText, setShellSNSearchText),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultFilteredValue: runNameSearchText ? [runNameSearchText] : null,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      ...getColumnSearchProps('name', false, runNameSearchText, setRunNameSearchText),
    },
    {
      title: 'User',
      dataIndex: 'userEmail',
      key: 'userEmail',
      defaultFilteredValue: userNameSearchText ? [userNameSearchText] : null,
      sorter: (a, b) => (a.userEmail > b.userEmail ? 1 : -1),
      ...getColumnSearchProps('userEmail', false, userNameSearchText, setUserNameSearchText),
    },
    {
      title: 'Date/Time',
      dataIndex: 'dateTimeRendered',
      key: 'dateTimeRendered',
      defaultSortOrder: 'descend',
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            onChange={(e) => {
              if (e !== null) {
                let _selectedRunsFrom: string[] = [];
                let [_from, _to] = e;
                if (_from !== null) {
                  let ts = _from.unix();
                  if (ts !== null && ts !== undefined) {
                    runs.forEach((run) => {
                      if (run.TimestampStart > ts * 1000) {
                        _selectedRunsFrom.push(run.UID);
                      }
                    });
                  }
                } else {
                  _selectedRunsFrom = [...runs.map((r) => r.UID)];
                }

                let _selectedRunsTo: string[] = [];
                if (_to !== null) {
                  let ts = _to.unix();
                  if (ts !== null && ts !== undefined) {
                    runs.forEach((run) => {
                      if (run.TimestampEnd < ts * 1000) {
                        _selectedRunsTo.push(run.UID);
                      }
                    });
                  }
                } else {
                  _selectedRunsTo = [...runs.map((r) => r.UID)];
                }
                let _selectedRuns: string[] = [];
                runs.forEach((run) => {
                  if (_selectedRunsFrom.includes(run.UID) && _selectedRunsTo.includes(run.UID)) {
                    _selectedRuns.push(run.UID);
                  }
                });
                if (_selectedRuns.length === 0) {
                  _selectedRuns.push('');
                }
                setSelectedKeys(_selectedRuns);
                confirm();
              } else {
                if (clearFilters !== undefined) {
                  clearFilters();
                  confirm();
                }
              }
            }}
            allowClear={true}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => {
        return record.uid === value;
      },
      sorter: (a, b) => a.timestampStart - b.timestampStart,
    },
    {
      title: 'Total records',
      dataIndex: 'recordsCount',
      key: 'recordsCount',
      sorter: (a, b) => a.recordsCount - b.recordsCount,
    },
    {
      title: 'Items',
      dataIndex: 'itemsRendered',
      key: 'items',
      defaultFilteredValue: selectedItems ? selectedItems.split(',') : [],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input placeholder="Search in filters" onChange={(e) => setFilteredItem(uniqueItems.filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase())))} />
            {filteredItem.map((item) => (
              <>
                <Row>
                  <Checkbox
                    value={item}
                    checked={selectedKeys.findIndex((key) => key === item) !== -1 ? true : false}
                    defaultChecked={selectedItems && selectedItems.split(',').findIndex((el) => el === item) !== -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (selectedKeys.findIndex((key) => key === e.target.value) === -1) return setSelectedKeys([...selectedKeys, e.target.value]);
                      } else {
                        const key = selectedKeys.findIndex((key) => key === e.target.value);
                        selectedKeys.splice(key, 1);
                        setSelectedKeys([...selectedKeys]);
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                </Row>
              </>
            ))}
            <Row>
              <Button
                onClick={() => {
                  setSelectedKeys([]);
                  setSelectedItems('');
                  clearFilters && clearFilters();
                  confirm();
                }}
                size="small"
                type="link"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setSelectedItems(selectedKeys.toString());
                  confirm();
                }}
              >
                OK
              </Button>
            </Row>
          </div>
        );
      },
      filterSearch: true,
      onFilter: (value, record) => {
        return record.items.includes(value as string);
      },
    },
    {
      title: 'Flags',
      key: 'flags',
      responsive: ['md'],
      filters: uniquePeptideSets.map((e) => {
        return { text: renderPeptideSetType(e), value: e };
      }),
      onFilter: (value, record) => {
        return record.peptideSetType === value;
      },
      render(value, record, index) {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {renderPeptideSetType(record.peptideSetType)} {renderSwVersion(record.swVersion)} {renderFwVersion(record.fwVersion)} {renderExposureIcon(record.calibrationExposure)}
          </div>
        );
      },
    },
    {
      title: 'User tags',
      dataIndex: 'userTags',
      key: 'userTags',
      filterSearch: true,
      filters: uniqueUserTags.map((e) => {
        return { text: e, value: e };
      }),
      onFilter: (value, record) => {
        return record.userTags.includes(value as string);
      },
      render: (value: string[]) => (
        <>
          <div style={{ overflow: 'scroll', maxHeight: '150px' }}>
            {value.map((tag) => (
              <Tag>{tag}</Tag>
            ))}
          </div>
        </>
      ),
    },
  ];

  if (selectedDeleteRuns && setSelectedDeleteRuns && userInfo && userInfo.feature_flags.includes(FeatureFlag.AADeleteRunsEnabled)) {
    columns.push({
      title: (
        <Row justify="center">
          <WithFeatureFlagsCheck ff={FeatureFlag.AADeleteRunsEnabled}>
            <FontAwesomeIcon icon="trash" style={{ color: aryballeColorPalette.gray }} />
          </WithFeatureFlagsCheck>
        </Row>
      ),
      dataIndex: 'uid',
      key: 'uid',
      render: (value: string) => {
        const seletedRuns = selectedRunsMap && selectedRunsMap[tsdbRef.Name] ? selectedRunsMap[tsdbRef.Name].map((run) => run.UID) : [];
        const findIdInSelectedRuns = seletedRuns.findIndex((uid) => uid === value);
        return (
          <Row justify="center">
            {findIdInSelectedRuns === -1 ? (
              <FontAwesomeIcon
                icon="trash"
                style={{
                  cursor: 'pointer',
                  color: selectedDeleteRuns && selectedDeleteRuns.includes(value) ? defaultColorPalette.red : aryballeColorPalette.gray,
                }}
                onClick={() => {
                  dispatch(setVisibleDeleteRuns(true));
                  if (selectedDeleteRuns.includes(value)) {
                    setSelectedDeleteRuns(selectedDeleteRuns.filter((el) => el !== value));
                  } else setSelectedDeleteRuns([...selectedDeleteRuns, value]);
                }}
              />
            ) : (
              <Popover placement="topRight" content="You can't delete this run because it is selected for analysis">
                <FontAwesomeIcon
                  icon="trash"
                  style={{
                    cursor: 'not-allowed',
                    color: '#e2e2e2',
                  }}
                />
              </Popover>
            )}
          </Row>
        );
      },
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <Table
        style={{ width: '100%' }}
        columns={columns}
        dataSource={dataSource}
        size="small"
        scroll={{ scrollToFirstRowOnChange: true, x: '100%' }}
        pagination={{
          defaultPageSize: 20,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Paragraph>
                <b>Run UID: </b> {record.uid}
                <br />
                <b>Device ID: </b>
                {renderDeviceID(record.deviceID)}
                <br />
                <b>Started: </b> {renderTimestamp(record.timestampStart)}
                <br />
                <b>Ended: </b> {renderTimestamp(record.timestampEnd)} (ended {renderTimeDuration(new Date(record.timestampEnd), new Date())} ago)
                <br />
                <b>Duration: </b> {renderTimeDuration(new Date(record.timestampStart), new Date(record.timestampEnd))}
                <br />
                <b>SW Version: </b> {record.swVersion} {renderSwVersion(record.swVersion)}
                <br />
                <b>FW Version: </b> {record.fwVersion} {renderFwVersion(record.fwVersion)}
                <br />
                <b>Exposure: </b> {record.calibrationExposure} μs {renderExposureIcon(record.calibrationExposure)}
                <br />
                <b>Peptides: </b> {Array.from(spotsGrid2PeptideSet(record.spotsGrid)).join(', ')} {renderPeptideSetType(record.peptideSetType)}
              </Typography.Paragraph>
            </>
          ),
          rowExpandable: () => true,
        }}
        rowSelection={{
          type: 'checkbox',
          onChange: (_selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            const findIdInSelectedDeleteRuns = selectedRows && selectedRows[selectedRows.length - 1] ? selectedDeleteRuns?.includes(selectedRows[selectedRows.length - 1].uid) : undefined;
            if (!findIdInSelectedDeleteRuns) {
              let _selectedRunsMap = { ...selectedRunsMap };
              _selectedRunsMap[tsdbRef.Name] = selectedRows.map((run) => ({
                TSDBRef: tsdbRef,
                UID: run.uid,
              }));
              setSelectedRunsMap(_selectedRunsMap);
            }
          },
          selectedRowKeys: selectedRunsMap && selectedRunsMap[tsdbRef.Name] && selectedRunsMap[tsdbRef.Name].map((run) => run.UID),
          getCheckboxProps: (record: DataType) => ({
            disabled: selectedDeleteRuns?.includes(record.uid),
          }),
        }}
      ></Table>
    </div>
  );
};
