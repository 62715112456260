import { Button, Col, Dropdown, Menu, Row, Space, Tooltip } from 'antd';
import { FC, useState } from 'react';
import { IntensityFigureType, IntensitySource } from '../../../types/analysisTypes';
import { ArrowsAltOutlined, DownOutlined } from '@ant-design/icons';
import IntensityFigureQualityControl from './IntensityFigureQualityControl';

export const IntensityPanelQualityControl: FC<{ sessionID: string; defaultIntensitySource?: IntensitySource; disabledPdf?: boolean; qualityControlMode?: boolean }> = (props) => {
  const { disabledPdf } = props;

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const [figureType, setFigureType] = useState<IntensityFigureType>(+IntensityFigureType.Bar);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      {!disabledPdf && (
        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
          Intensity
        </h3>
      )}
      <Row justify="space-between" align="middle" className="pdf-invisible" style={{ width: '100%' }}>
        <Col></Col>
        <Col>
          <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(IntensityFigureType)
                    .filter(([_, value]) => typeof value === 'number')
                    .map(([label, value]) => {
                      return {
                        key: +value,
                        label: label,
                        onClick: () => {
                          setFigureType(+value);
                        },
                      };
                    })}
                />
              }
            >
              <Tooltip title="Chart type">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {IntensityFigureType[figureType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          </Space>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {/* {humidityCalibrationCalibrantName && intensitySource === IntensitySource.Signature && (
                <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
                  <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
                </Popover>
              )} */}
          {!disabledPdf && <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />}
        </Col>
      </Row>

      <IntensityFigureQualityControl isVisibleModal={isVisibleModal} setIsVisibleModal={setIsVisibleModal} figureType={figureType} />
    </div>
  );
};
