import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../../app/hooks';
import { aryballeColorPalette, defaultColorPalette, colorToTransparent, peptideColorPaletteVdW, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, nonStandardPeptidesColor } from '../../../../compute/colormap';
import { defaultPlotlyArguments, getPeptideSetType, sum } from '../../../../compute/utils';
import { selectHumidityCompensationCalibrantName, selectHumidityCompensationPositionOffset } from '../../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, AuxiliarySensorPrettyNameMap, AuxiliarySensorType, EligibleAditionalSensorType, PeptideSet, TemperaturePrettyNameMap, TemperatureSeriesType } from '../../../../types/analysisTypes';

export const SingleSensogramFigure: React.FC<{
  record: AryRecord;
  sensors: string[] | undefined;
  boundaries: number[];
  drawHumidity: boolean;
  humidityReferencePosition: number | null;
  drawTemperature: boolean;
  temperatureType: TemperatureSeriesType;
  drawAuxiliarySensor: boolean;
  auxiliarySensorType: AuxiliarySensorType | undefined;
  drawAdditionalSensor: boolean;
  additionalSensorType: EligibleAditionalSensorType | undefined;
  cmap: Record<string, string> | undefined;
  mutateBoundaries: (startIdx: number, endIdx: number) => void;
  overridePlotlyLayout?: Partial<Plotly.Layout>;
  overridePlotlyConfig?: Partial<Plotly.Config>;
}> = (props) => {
  const {
    record,
    sensors,
    boundaries,
    drawHumidity,
    humidityReferencePosition,
    drawTemperature,
    temperatureType,
    drawAuxiliarySensor,
    auxiliarySensorType,
    drawAdditionalSensor,
    additionalSensorType,
    cmap,
    mutateBoundaries,
    overridePlotlyConfig,
    overridePlotlyLayout,
  } = props;

  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const [peptidesSetType, setPeptidesSetType] = useState<PeptideSet>(PeptideSet.Unknown);

  useEffect(() => {
    if (sensors) {
      const spotgrid = [...sensors.map((s) => parseInt(s))];
      setPeptidesSetType(getPeptideSetType(spotgrid, true));
    } else setPeptidesSetType(PeptideSet.Unknown);
  }, [sensors]);

  if (record.DataSeries === null || record.Sensors === null || record.DataSeries.length === 0 || record.Sensors.length === 0) {
    return (
      <div>
        <Alert type="error" message="No sensors selected" />
      </div>
    );
  }

  var apexMax = 0;
  record.DataSeries.forEach((span) => {
    apexMax = Math.max(apexMax, Math.max(...span));
  });

  var apexMin = 0;
  record.DataSeries.forEach((span) => {
    apexMin = Math.min(apexMin, Math.min(...span));
  });

  let blColor = aryballeColorPalette.gray;
  let blColorLine = blColor;
  let blColorFill = colorToTransparent(blColor, 40);

  let anColor = aryballeColorPalette.cyan;
  if (cmap !== undefined) anColor = defaultColorPalette[cmap[record.ItemName]];

  let anColorLine = anColor;
  let anColorFill = colorToTransparent(anColorLine, 40);

  let baselineLeft = boundaries[0] / 3;
  let baselineRight = (boundaries[1] - 1) / 3;
  let analyteLeft = boundaries[2] / 3;
  let analyteRight = (boundaries[3] - 1) / 3;

  var plotlyData: Plotly.Data[] = [];

  const frameSeries = record.RelativeTimeSeries.map((_, i) => i / 3);

  record.DataSeries.forEach((span, i) => {
    let peptideCodeInt = parseInt(record.Sensors[i]); // TODO: paseInt -> parsePeptide
    let color: string = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;

    if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2 || peptidesSetType === PeptideSet.POR3 || peptidesSetType === PeptideSet.POR4) color = peptideColorPaletteVdW[peptideCodeInt];
    else if (peptidesSetType === PeptideSet.NonStandard) {
      color = nonStandardPeptidesColor[peptideCodeInt % nonStandardPeptidesColor.length];
    }

    if (color === undefined) {
      color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
    }
    plotlyData.push({
      type: 'scatter',
      x: frameSeries.map((e) => e.toFixed(1)),
      y: span.map((e) => e.toFixed(3)),
      name: record.Sensors[i],
      legendgroup: '&nbsp;' + peptideCodeInt,
      line: {
        color: color,
      },
    });
  });

  plotlyData.push({
    type: 'scatter',
    x: [baselineLeft, baselineLeft],
    y: [apexMin, apexMax],
    name: 'baseline_start',
    legendgroup: '_',
    showlegend: false,
    line: {
      color: blColorLine,
    },
  });
  plotlyData.push({
    type: 'scatter',
    x: [baselineRight, baselineRight],
    y: [apexMin, apexMax],
    name: 'baseline_end',
    legendgroup: '_',
    showlegend: false,
    fill: 'tonextx',
    line: {
      color: blColorLine,
    },
    fillcolor: blColorFill,
  });
  plotlyData.push({
    type: 'scatter',
    x: [analyteLeft, analyteLeft],
    y: [apexMin, apexMax],
    name: 'analyte_start',
    legendgroup: '_',
    showlegend: false,
    line: {
      color: anColorLine,
    },
  });
  plotlyData.push({
    type: 'scatter',
    x: [analyteRight, analyteRight],
    y: [apexMin, apexMax],
    name: 'analyte_end',
    legendgroup: '_',
    showlegend: false,
    fill: 'tonextx',
    line: {
      color: anColorLine,
    },
    fillcolor: anColorFill,
  });

  // HUMIDITY
  const shouldActuallyDrawHumidity = drawHumidity && record.HumiditySeries !== null && sum(record.HumiditySeries) !== 0;

  const humiditySeries = record.HumiditySeries.map((v) => v * 100);

  let maxH = -1e6;
  let minH = 1e6;
  let baselineHAcc = 0;
  let baselineHNb = 0;

  // Get the maximum humidityreference point location
  humiditySeries.forEach((v, i) => {
    if (v >= maxH) {
      maxH = v;
    }
    if (v <= minH) {
      minH = v;
    }
    if (i >= boundaries[0] && i < boundaries[1]) {
      baselineHAcc += v;
      baselineHNb++;
    }
  });

  baselineHAcc /= baselineHNb;

  if (shouldActuallyDrawHumidity) {
    plotlyData.push({
      type: 'scatter',
      x: frameSeries,
      y: humiditySeries,
      name: 'humidity',
      legendgroup: 'humidity',
      line: {
        color: aryballeColorPalette.pink,
        dash: 'dashdot',
      },
      yaxis: 'y2',
    });
  }

  // TEMPERATURE
  var actualTemperatureSeries: number[] = [];
  switch (temperatureType) {
    case +TemperatureSeriesType.SensorTemperature:
      actualTemperatureSeries = record.TemperatureSeries;
      break;
    case +TemperatureSeriesType.ThermodesorptionTemperature:
      actualTemperatureSeries = record.ThermodesorptionTemperatureSeries;
      break;
  }
  const shouldActuallyDrawTemperature = drawTemperature && !drawAuxiliarySensor && actualTemperatureSeries !== null && sum(actualTemperatureSeries) !== 0;

  if (shouldActuallyDrawTemperature) {
    plotlyData.push({
      type: 'scatter',
      x: frameSeries,
      y: actualTemperatureSeries,
      name: `${TemperaturePrettyNameMap[temperatureType].name}, ${TemperaturePrettyNameMap[temperatureType].unit}`,
      legendgroup: `${TemperaturePrettyNameMap[temperatureType].name}, ${TemperaturePrettyNameMap[temperatureType].unit}`,
      line: {
        color: aryballeColorPalette.cyan,
        dash: 'dashdot',
      },
      yaxis: 'y3',
    });
  }

  // AUXILIARY SENSOR
  var actualAuxiliarySensorSeries: number[] = [];
  switch (auxiliarySensorType) {
    case +AuxiliarySensorType.SunriseCo2:
      actualAuxiliarySensorSeries = record.SunriseCo2Series;
      break;
    case +AuxiliarySensorType.ZephyrAirflow:
      actualAuxiliarySensorSeries = record.ZephyrAirflowSeries;
      break;
    case +AuxiliarySensorType.PidVoc:
      actualAuxiliarySensorSeries = record.PidVocSeries;
      break;
    case +AuxiliarySensorType.Pms1:
      actualAuxiliarySensorSeries = record.Pms1Series;
      break;
    case +AuxiliarySensorType.Pms25:
      actualAuxiliarySensorSeries = record.Pms25Series;
      break;
    case +AuxiliarySensorType.Pms10:
      actualAuxiliarySensorSeries = record.Pms10Series;
      break;
    case +AuxiliarySensorType.BmeVoc:
      actualAuxiliarySensorSeries = record.BmeVocSeries;
      break;
    case +AuxiliarySensorType.BmeCo2:
      actualAuxiliarySensorSeries = record.BmeCo2Series;
      break;
    case +AuxiliarySensorType.PidRawVoc:
      actualAuxiliarySensorSeries = record.PidRawVocSeries;
      break;
    case +AuxiliarySensorType.BmeHumidity:
      actualAuxiliarySensorSeries = record.BmeHumiditySeries;
      break;
    case +AuxiliarySensorType.BmeTemperature:
      actualAuxiliarySensorSeries = record.BmeTemperatureSeries;
      break;
    case +AuxiliarySensorType.BmeRawPressure:
      actualAuxiliarySensorSeries = record.BmeRawPressureSeries;
      break;
    case +AuxiliarySensorType.BmeBreathVoc:
      actualAuxiliarySensorSeries = record.BmeBreathVocSeries;
      break;
  }

  const shouldActuallyDrawAuxiliarySensor = drawAuxiliarySensor && actualAuxiliarySensorSeries !== null && sum(actualAuxiliarySensorSeries) !== 0;

  if (shouldActuallyDrawAuxiliarySensor && auxiliarySensorType) {
    plotlyData.push({
      type: 'scatter',
      x: frameSeries,
      y: actualAuxiliarySensorSeries,
      name: AuxiliarySensorPrettyNameMap[auxiliarySensorType].name,
      legendgroup: AuxiliarySensorPrettyNameMap[auxiliarySensorType].name,
      line: {
        color: aryballeColorPalette.gray,
      },
      yaxis: 'y3',
    });
  }

  // ADDITIONAL SENSOR
  var actualAdditionalSensorSeries: number[] = [];
  if (record.AdditionalSensorsSeries) {
    var labelAdditionalSensor = additionalSensorType?.Type + ':' + additionalSensorType?.Name;
    if (additionalSensorType?.Unit) labelAdditionalSensor = labelAdditionalSensor + ':' + additionalSensorType?.Unit;
    const _actualAdditionalSensorSeries = Object.entries(record.AdditionalSensorsSeries).map(([label, values]) => label === labelAdditionalSensor && values)[0];
    if (_actualAdditionalSensorSeries !== false) actualAdditionalSensorSeries = _actualAdditionalSensorSeries;
  }

  const shouldActuallyDrawAdditionalSensor = drawAdditionalSensor && actualAdditionalSensorSeries !== null && sum(actualAdditionalSensorSeries) !== 0;

  if (shouldActuallyDrawAdditionalSensor && additionalSensorType) {
    plotlyData.push({
      type: 'scatter',
      x: frameSeries,
      y: actualAdditionalSensorSeries,
      name: additionalSensorType.Name + ' (' + additionalSensorType.Type + ')',
      legendgroup: additionalSensorType.Name + ' (' + additionalSensorType.Type + ')',
      line: {
        color: aryballeColorPalette.gray,
      },
      yaxis: 'y3',
    });
  }

  // CONFIG PLOTLY
  let plotlyConfig: Partial<Plotly.Config> = {
    ...defaultPlotlyArguments.config,
  };
  plotlyConfig.displayModeBar = true;
  plotlyConfig.modeBarButtons = [['zoom2d', 'select2d'] as Plotly.ModeBarDefaultButtons[]];

  if (overridePlotlyConfig) {
    plotlyConfig = { ...plotlyConfig, ...overridePlotlyConfig };
  }

  var plotlyLayout: Partial<Plotly.Layout> = {
    ...defaultPlotlyArguments.layout,
  };
  plotlyLayout.dragmode = 'select';
  plotlyLayout.datarevision = Math.random();
  plotlyLayout.yaxis = {
    title: { text: 'Intensity, rad' },
    automargin: true,
  };
  plotlyLayout.xaxis = {
    title: { text: 'Time, sec', position: 'bottom left' },
    range: [0, frameSeries[frameSeries.length - 1]],
    automargin: true,
  };
  if (shouldActuallyDrawAuxiliarySensor && auxiliarySensorType) {
    plotlyLayout.xaxis.domain = [0, 0.9];
    plotlyLayout.yaxis3 = {
      overlaying: 'y',
      side: 'right',
      title: `${AuxiliarySensorPrettyNameMap[auxiliarySensorType].name}, ${AuxiliarySensorPrettyNameMap[auxiliarySensorType].unit}`,
      anchor: 'free',
      position: 1,
      automargin: true,
    };
  }
  if (shouldActuallyDrawAdditionalSensor && additionalSensorType) {
    var titleAdditionalSensorType = additionalSensorType.Name + '(' + additionalSensorType.Type + ')';
    if (additionalSensorType.Unit !== null) titleAdditionalSensorType += `, ${additionalSensorType.Unit}`;
    plotlyLayout.xaxis.domain = [0, 0.9];
    plotlyLayout.yaxis3 = {
      overlaying: 'y',
      side: 'right',
      title: titleAdditionalSensorType,
      anchor: 'free',
      position: 1,
      automargin: true,
    };
  }
  if (shouldActuallyDrawTemperature && !shouldActuallyDrawAuxiliarySensor) {
    plotlyLayout.xaxis.domain = [0, 0.9];
    plotlyLayout.yaxis3 = {
      overlaying: 'y',
      side: 'right',
      title: `${TemperaturePrettyNameMap[temperatureType].name}, ${TemperaturePrettyNameMap[temperatureType].unit}`,
      anchor: 'free',
      position: 1,
      automargin: true,
    };
  }
  if (shouldActuallyDrawHumidity) {
    plotlyLayout.yaxis2 = {
      overlaying: 'y',
      side: 'right',
      title: 'Humidity, %RH',
      automargin: true,
    };
  }

  if (shouldActuallyDrawHumidity && humidityCalibrationCalibrantName && humidityReferencePosition !== null) {
    let analyteMidPoint = (boundaries[2] + boundaries[3]) / 2;
    plotlyLayout.annotations = [
      {
        yref: 'y2',
        x: humidityReferencePosition / 3,
        y: humiditySeries[humidityReferencePosition],
        ax: 0,
        ay: -30,
        showarrow: true,
        text: '💧',
        font: {
          size: 12,
        },
        opacity: 0.75,
        arrowwidth: 1.5,
        arrowhead: 6,
        hovertext: `Reference humidity value for humidity compensation${humidityCalibrationPositionOffset !== 0 ? `<br>Manual offset is taken into account (${(humidityCalibrationPositionOffset / 3).toFixed(1)}s)` : ''}`,
      },
      {
        yref: 'y2',
        x: humidityReferencePosition / 3,
        y: humiditySeries[humidityReferencePosition],
        ax: humidityReferencePosition >= analyteMidPoint ? boundaries[3] / 3 - 1 : boundaries[2] / 3 + 1,
        ay: 0,
        axref: 'x',
        showarrow: true,
        font: {
          size: 12,
        },
        opacity: 0.75,
        arrowwidth: 1.5,
        arrowhead: 3,
        arrowside: 'start',
        text: `Δt=${(humidityReferencePosition / 3 - analyteMidPoint / 3).toFixed(1)}s`,
        xanchor: 'center',
        yanchor: 'bottom',
      },
      {
        yref: 'y2',
        x: humidityReferencePosition / 3,
        y: humiditySeries[humidityReferencePosition],
        ax: 0,
        ay: minH,
        ayref: 'y2',
        showarrow: true,
        font: {
          size: 12,
        },
        opacity: 0.75,
        arrowwidth: 1.5,
        arrowhead: 2,
        arrowside: 'start',
        text: `ΔRH=${(humiditySeries[humidityReferencePosition] - baselineHAcc).toFixed(1)}%`,
      },
    ];
  }

  if (overridePlotlyLayout) {
    plotlyLayout = { ...plotlyLayout, ...overridePlotlyLayout };
  }

  return (
    <Plot
      divId={`record_${record.ID}_sensogram_scatter_plot`}
      data={plotlyData}
      layout={plotlyLayout}
      config={plotlyConfig}
      onSelected={(e) => {
        if (e.range === undefined) {
          return;
        }

        let range = e.range.x;
        let [startTime, endTime] = range;
        let startIdx = Math.round(startTime * 3);
        let endIdx = Math.round(endTime * 3);
        mutateBoundaries(startIdx, endIdx);
      }}
      style={defaultPlotlyArguments.style}
      useResizeHandler={true}
    />
  );
};
