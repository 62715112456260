import { Button, Drawer } from 'antd';
import { CSSProperties, Key, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectVisibleSettings, setVisibleSettings } from '../../../features/analysisConfig/sessionInfoSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { WithFeatureFlagsCheck } from '../../../with_feature_flags_check';
import AggregatePeptidesSettings from './AggregatePeptidesSettings';
import ChemicalCalibrationSettings from './ChemicalCalibrationSettings';
import HumidityCompensationSettings from './HumidityCompensationSettings';
import PeptidesSelectorSettings from './PeptidesSelectorSettings';
import SigmaMultiplierSettings from './SigmaMultiplierSettings';
import SubstractItemSettings from './SubstractItemSettings';
import DisplayResultSettings from './DisplayResultSettings';
import ThresholdSignatureSettings from './ThresholdSignatureSettings';
import ThresholdIntensitySettings from './ThresholdIntensitySettings';
import InterpretationResultSettings from './InterpretationResultSettings';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { selectModel, selectSessionID } from '../../../features/analysisConfig/analysisConfigSlice';
import { RunMetadata } from '../../../types/runsType';
import ModalComponent from '../../../components/Modal/ModalComponent';
import SaveAnalysisConfig from './SaveAnalysisConfig';
import { useOktaAuth } from '@okta/okta-react';

type DashboardSettingsProps = {};

type typeStyle = {
  [K in Key]: CSSProperties;
};

const DashboardSettings: React.FC<DashboardSettingsProps> = () => {
  const { authState } = useOktaAuth();
  const sessionID = useAppSelector(selectSessionID);
  const visibleSettings = useAppSelector(selectVisibleSettings);
  const model = useAppSelector(selectModel);
  const [activeComparisonSession, setActiveComparisonSession] = useState<boolean>(true);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const dispatch = useAppDispatch();
  const style: typeStyle = {
    container: { position: 'relative', height: '100vh', width: visibleSettings ? 400 : 0, padding: visibleSettings ? 48 : 0, overflow: 'hidden', textAlign: 'center' },
  };

  // set user info
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setActiveComparisonSession(
              runs
                .map((run) => run.Tags)
                .flat()
                .includes('$comparison-analysis')
            );
          }
        });
    }
  }, [authState, sessionID]);

  return (
    <div style={style.container}>
      <Drawer
        title="Dashboard settings"
        placement="right"
        visible={visibleSettings}
        getContainer={false}
        bodyStyle={{ overflowY: 'scroll', textAlign: 'left', paddingBottom: 60, marginBottom: 50 }}
        style={{ position: 'fixed', right: 0, top: 50, width: style.container.width, height: 'calc(100% - 50px)' }}
        headerStyle={{ borderBottom: 'none' }}
        mask={false}
        onClose={() => dispatch(setVisibleSettings(false))}
      >
        <WithFeatureFlagsCheck ff={FeatureFlag.AANonAggregatedPeptidesAccessEnabled}>
          <AggregatePeptidesSettings userInfo={userInfo} />
        </WithFeatureFlagsCheck>
        <HumidityCompensationSettings userInfo={userInfo} />
        <WithFeatureFlagsCheck ff={FeatureFlag.AAChemicalCalibrationAccessEnabled}>
          <ChemicalCalibrationSettings />
        </WithFeatureFlagsCheck>
        <PeptidesSelectorSettings />
        <SubstractItemSettings userInfo={userInfo} />
        {/* <SigmaMultiplierSettings userInfo={userInfo} /> */}
        {activeComparisonSession && (
          <>
            <DisplayResultSettings userInfo={userInfo} />
            {!model && (
              <>
                <ThresholdSignatureSettings userInfo={userInfo} />
                <ThresholdIntensitySettings userInfo={userInfo} />
                <InterpretationResultSettings userInfo={userInfo} />
              </>
            )}
            {userInfo && !userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl) && <SaveAnalysisConfig />}
          </>
        )}
      </Drawer>
    </div>
  );
};

export default DashboardSettings;
