import { Alert, Button, Col, Dropdown, Menu, Popover, Row, Skeleton, Space, Tooltip, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import CardSection from '../../components/section/CardSection';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectInterpretation,
  selectModel,
  selectQualityControlMethod,
  selectRecords,
  selectSensogramRenderType,
  selectSubtractItemName,
  selectThresholdIntensity,
  selectThresholdSignature,
} from '../../features/analysisConfig/analysisConfigSlice';
import { selectVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { SignaturePanel } from '../../components/graphic/signature/SignaturePanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultColorPalette } from '../../compute/colormap';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { IntensityPanel } from '../../components/graphic/intensity/Intensity';
import { arycolor } from '../../assets/css/color';
import { CustomComparisonResult, CustomResult, DisplayResult, InterpretationDecisionType, ObjectiveDecisionType, ResultComparison, SensogramRenderType, UnitComparisonResult } from '../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';
import { FormatErrValue } from '../../components/FormatErrValue';
import { FeatureFlag, UserClaimsWithTSDB } from '../../types/userType';
import { useOktaAuth } from '@okta/okta-react';
import { MultiSignaturesWidget } from '../../components/graphic/signature/MultiSignaturesWidget';
import { MultiSensogram } from '../../components/graphic/sensogram/MultiSensogram';
import { IntensityPanelQualityControl } from '../../components/graphic/intensity/IntensityQualityControl';
import { MultiSensogramQualityControl } from '../../components/graphic/sensogram/MultiSensogramQualityControl';

type DashboardMainQualityControlProps = {
  isExpendedMenu: boolean;
};

const DashboardMainQualityControl: React.FC<DashboardMainQualityControlProps> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);

  const { sessionID } = useParams<{ sessionID: string }>();
  const [intensityResult, setIntensityResult] = useState<ResultComparison | null>();
  const [signatureResult, setSignatureResult] = useState<ResultComparison | null>();
  const [customResults, setCustomResults] = useState<CustomComparisonResult | null>();
  const [customResult, setCustomResult] = useState<UnitComparisonResult>();
  const [displayResult, setDisplayResult] = useState<DisplayResult | CustomResult>();

  const subtractItemName = useAppSelector(selectSubtractItemName);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const thresholdIntensity = useAppSelector(selectThresholdIntensity);
  const thresholdSignature = useAppSelector(selectThresholdSignature);
  const qualityControlMethod = useAppSelector(selectQualityControlMethod);
  const interpretation = useAppSelector(selectInterpretation);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);
  const model = useAppSelector(selectModel);
  const currentItemName = useAppSelector(selectCurrentItemName);
  const sensogramRenderType = useAppSelector(selectSensogramRenderType);
  const currentRecordID = useAppSelector(selectCurrentRecordID);

  const records = useAppSelector(selectRecords);
  const visibleSettings = useAppSelector(selectVisibleSettings);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { authState } = useOktaAuth();

  // set user info
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (authState === null || !authState.accessToken || model) return;

    fetchAuthorizedAPIEndpoint(`/compare/signatures?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          qualityControlMethod,
          interpretation,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: any) => {
        setSignatureResult(data);
      })
      .catch((e) => {
        setSignatureResult(null);
        console.log(e);
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    thresholdIntensity,
    thresholdSignature,
    qualityControlMethod,
    interpretation,
  ]);

  useEffect(() => {
    if (authState === null || !authState.accessToken || model) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compare/intensities?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          qualityControlMethod,
          interpretation,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: any) => {
        setIntensityResult(data);
      })
      .catch((e) => {
        setIntensityResult(null);
        console.log(e);
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    thresholdIntensity,
    thresholdSignature,
    qualityControlMethod,
    interpretation,
  ]);

  useEffect(() => {
    if (authState === null || !authState.accessToken || !model) return;

    fetchAuthorizedAPIEndpoint(`/compare/custom?session_id=${sessionID}&pipe=temporal_profile`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          qualityControlMethod,
          interpretation,
        },
        model,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: CustomComparisonResult) => {
        setCustomResults(data);
      })
      .catch((e) => {
        setCustomResults(null);
        console.log(e);
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    thresholdIntensity,
    thresholdSignature,
    qualityControlMethod,
    interpretation,
    model,
  ]);

  useEffect(() => {
    if (!customResults) return;
    if (sensogramRenderType === SensogramRenderType.Item) setCustomResult(customResults[sensogramRenderType].find((result) => result.ID === currentItemName));
    else if (sensogramRenderType === SensogramRenderType.Record) {
      setCustomResult(
        customResults[sensogramRenderType].find((result) => {
          return result.ID === currentRecordID;
        })
      );
    }
  }, [currentItemName, currentRecordID, customResults, sensogramRenderType]);

  useEffect(() => {
    if (model) return setDisplayResult(CustomResult.Custom);
    if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)) {
      setDisplayResult(qualityControlMethod);
    } else setDisplayResult(DisplayResult.AllResult);
  }, [qualityControlMethod, userInfo, model]);

  const textDecisionColor = (result: ResultComparison | null | undefined) => {
    if (result?.InterpretedDecision === InterpretationDecisionType.PASS) return defaultColorPalette.green;
    else if (result?.InterpretedDecision === InterpretationDecisionType.FAIL) return defaultColorPalette.red;
    else return 'black';
  };

  const textDecision = (result: ResultComparison | null | undefined) => {
    let textInterpretation = '';
    if (result?.InterpretedDecision === InterpretationDecisionType.NA) textInterpretation = 'Assumed ';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.SIMILAR) textInterpretation += 'Similar';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.DIFFERENT) textInterpretation += 'Different';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.NA) textInterpretation = 'Unknown';
    return textInterpretation;
  };

  return (
    <Col id="dashbord-main" style={{ marginLeft: props.isExpendedMenu ? 350 : 70, width: props.isExpendedMenu ? 'calc(100% - 350px)' : 'calc(100% - 70px)' }}>
      <CardSection scroll="hidden">
        <Row justify="end" style={{ marginBottom: 20 }}>
          <Col>
            {humidityCalibrationCalibrantName && (
              <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
                <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
              </Popover>
            )}
            {userInfo && !userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl) && (
              <Dropdown
                overlay={
                  <Menu
                    items={(model ? Object.entries(CustomResult) : Object.entries(DisplayResult)).map(([label, value]) => {
                      return {
                        key: label,
                        label: value,
                        onClick: () => {
                          setDisplayResult(value);
                        },
                      };
                    })}
                  />
                }
              >
                <Tooltip title="Display result ">
                  <Button style={{ borderRadius: '5px' }}>
                    <Space>
                      {displayResult}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Tooltip>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Row gutter={[20, 20]} style={{ marginBottom: 50, justifyContent: displayResult === DisplayResult.AllResult ? 'space-between' : 'center' }}>
          {displayResult !== DisplayResult.IntensityResult && displayResult !== CustomResult.Custom && (
            <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
              <Typography.Title level={5} style={{ textAlign: 'center', marginBottom: 60 }}>
                Signature Result
              </Typography.Title>

              <div style={{ textAlign: 'center' }}>
                {signatureResult?.InterpretedDecision === InterpretationDecisionType.PASS && <FontAwesomeIcon icon="check-circle" style={{ color: defaultColorPalette.green, fontSize: 50, marginBottom: 10 }} />}
                {signatureResult?.InterpretedDecision === InterpretationDecisionType.FAIL && <FontAwesomeIcon icon="times-circle" style={{ color: defaultColorPalette.red, fontSize: 50, marginBottom: 10 }} />}
                <p style={{ fontWeight: 'bold', color: textDecisionColor(signatureResult) }}>{textDecision(signatureResult)}</p>
                {signatureResult?.DistanceAvg && signatureResult?.DistanceStd && (
                  <Row align="middle" justify="center">
                    <span style={{ marginRight: 5 }}>Distance:</span>
                    <FormatErrValue avg={signatureResult.DistanceAvg * 100} err={signatureResult.DistanceStd * 100} />
                  </Row>
                )}
              </div>
            </Col>
          )}
          {displayResult !== DisplayResult.SignatureResult && displayResult !== CustomResult.Custom && (
            <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
              <Typography.Title level={5} style={{ textAlign: 'center', marginBottom: 60 }}>
                Intensity Result
              </Typography.Title>

              <div style={{ textAlign: 'center' }}>
                {intensityResult?.InterpretedDecision === InterpretationDecisionType.PASS && <FontAwesomeIcon icon="check-circle" style={{ color: defaultColorPalette.green, fontSize: 50, marginBottom: 10 }} />}
                {intensityResult?.InterpretedDecision === InterpretationDecisionType.FAIL && <FontAwesomeIcon icon="times-circle" style={{ color: defaultColorPalette.red, fontSize: 50, marginBottom: 10 }} />}
                <p style={{ fontWeight: 'bold', color: textDecisionColor(intensityResult) }}>{textDecision(intensityResult)}</p>
                {intensityResult?.DistanceAvg !== undefined && intensityResult?.DistanceStd !== undefined && (
                  <Row align="middle" justify="center">
                    <span style={{ marginRight: 5 }}>Relative Difference:</span>
                    <span>{(intensityResult.DistanceAvg * 100).toFixed(2)}%</span>
                  </Row>
                )}
              </div>
            </Col>
          )}
          {displayResult === CustomResult.Custom && (
            <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
              <Typography.Title level={5} style={{ textAlign: 'center', marginBottom: 60 }}>
                Result
              </Typography.Title>
              {customResult ? (
                <div style={{ textAlign: 'center' }}>
                  {customResult?.InterpretedDecision === InterpretationDecisionType.PASS && <FontAwesomeIcon icon="check-circle" style={{ color: defaultColorPalette.green, fontSize: 50, marginBottom: 10 }} />}
                  {customResult?.InterpretedDecision === InterpretationDecisionType.FAIL && <FontAwesomeIcon icon="times-circle" style={{ color: defaultColorPalette.red, fontSize: 50, marginBottom: 10 }} />}
                  <p style={{ fontWeight: 'bold' }}>
                    {customResult.ObjectiveDecision}: {(customResult.Probability * 100).toFixed(2)}%
                  </p>
                </div>
              ) : currentItemName === humidityCalibrationCalibrantName ? (
                <Alert
                  style={{ marginTop: '10px' }}
                  type="info"
                  message={
                    <>
                      This item is used as <b>humidity calibrant</b> and its signature is not available
                    </>
                  }
                ></Alert>
              ) : (
                <Alert style={{ marginTop: '10px' }} type="info" message={<>This selected item or record is not checked so its result is not available</>}></Alert>
              )}
            </Col>
          )}
        </Row>
      </CardSection>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
        <span>Details</span>
        {isDropdownOpen ? (
          <FontAwesomeIcon icon="caret-down" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(false)} />
        ) : (
          <FontAwesomeIcon icon="caret-right" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(true)} />
        )}
      </div>
      {isDropdownOpen && (
        <Row justify="space-between" gutter={[20, 10]} style={{ marginTop: '10px' }}>
          <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
            <CardSection>
              <div id="signature-panel-div" style={{ height: '50vh' }}>
                {records === undefined || records.length === 0 ? (
                  <Skeleton active />
                ) : (
                  <>
                    {model ? (
                      <>
                        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
                          Temporal Profile
                        </h3>
                        <MultiSensogramQualityControl />
                      </>
                    ) : (
                      <>
                        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
                          Signatures
                        </h3>
                        <MultiSignaturesWidget qualityControlMode />
                      </>
                    )}
                  </>
                )}
              </div>
            </CardSection>
          </Col>
          <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
            <CardSection>
              <div id="intensity-panel-div" style={{ height: '50vh' }}>
                {records === undefined || records.length === 0 ? <Skeleton active /> : <IntensityPanelQualityControl sessionID={sessionID} />}
              </div>
            </CardSection>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default DashboardMainQualityControl;
